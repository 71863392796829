@use '@angular/material' as mat;
@mixin icon-select-dialog-theme($theme) {
    $primary: map-get($theme, primary);

    app-icon-select-dialog {
        .iconwrapper {
            &:hover {
                background-color: mat.get-color-from-palette($primary);
                > fa-icon {
                    color: mat.get-color-from-palette($primary, default-contrast);
                }
            }
        }

        .iconwrapper {
            &.selectedIcon {
                background-color: mat.get-color-from-palette($primary);
                > fa-icon {
                    color: mat.get-color-from-palette($primary, default-contrast);
                }
            }
        }
    }
}
