@use '@angular/material' as mat;
@mixin app-new-document-view-component-theme($theme) {
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    app-new-document-view-component {
        dropzone {
            .input,
            .pen-icon {
                color: mat.get-color-from-palette($foreground, text);
            }
        }
    }
}
