@use '@angular/material' as mat;
@mixin app-support-dialog-theme($theme) {
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    app-support-dialog {
        .card {
            background-color: mat.get-color-from-palette($background, background);
        }
        a.link {
            color: mat.get-color-from-palette($foreground, text);
        }
    }
}
