@use '@angular/material' as mat;
@mixin dashboard-side-elements-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);

    app-history-side-elements {
        .component {
            background-color: mat.get-color-from-palette($background, card);
            > * {
                > header.header {
                    background-color: mat.get-color-from-palette($primary, darker);
                    color: mat.get-color-from-palette($primary, default-contrast);
                }
            }
        }
    }
}
