@use '@angular/material' as mat;
@mixin app-settings-list-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    app-settings-list {
        a.highlight {
            background-color: mat.get-color-from-palette($background, background);
        }
        .wrapper {
            background-color: mat.get-color-from-palette($background, background);
        }
        .clickable-list-container {
            &:before {
                border-right-color: mat.get-color-from-palette($foreground, divider);
            }
        }
    }
}
