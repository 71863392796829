@use '@angular/material' as mat;
@mixin app-process-main-view-theme($theme) {
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    app-process-main-view {
        .detection-modal-wrapper {
            background: mat.get-color-from-palette($background, card);
            .border-wrapper.border-wrapper {
                border-color:  mat.get-color-from-palette($primary);
            }
        }
        .main-file-badge {
            color:  mat.get-color-from-palette($primary);
        }
    }
}
