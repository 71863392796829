@mixin app-invoice-calculation-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    //@debug "#{$background}";
    app-invoice-calculation {
        .invoice-calculation-table tr:nth-child(even) {
            background-color: map-get($foreground, divider);
            //background-color: map-get($background, hover);
        }
    }
}
