@use '../../../../../node_modules/@angular/material/index' as mat;
@mixin app-time-tracker-multiple-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-time-tracker-multiple {
        .timer-card {
            background-color: mat.get-color-from-palette($primary, lighter);
            color: mat.get-color-from-palette($primary, lighter-contrast);
        }
    }
}
