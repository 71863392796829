@use '@angular/material' as mat;
@mixin logout-theme($theme) {
    $primary: map-get($theme, primary);

    app-logout {
        .button.active,
        .button:hover {
            background-color: mat.get-color-from-palette($primary, default-contrast);
            color: mat.get-color-from-palette($primary);
        }
    }
}
