@use '@angular/material' as mat;
@import 'base';
@mixin detail-commission-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);

    app-commission {
        .events .tab-header .mat-badge-content {
            background: $unseen-color;
        }
        .tab-group {
            background-color: mat.get-color-from-palette($background, card);
        }

        .files-wrapper {
            .file {
                border: 1px solid mat.get-color-from-palette($background, status-bar);

                &:hover {
                    background-color: mat.get-color-from-palette($background, status-bar);
                }

                .image-wrapper {
                    background-color: mat.get-color-from-palette($background, background);
                }

                .fileName {
                    color: mat.get-color-from-palette($primary);

                    &:hover {
                        color: mat.get-color-from-palette($primary, lighter);
                    }
                }

                .editing {
                    color: white;
                    background-color: mat.get-color-from-palette($warn);

                    &:hover {
                        background-color: mat.get-color-from-palette($warn, lighter);
                        color: mat.get-color-from-palette($accent);
                    }
                }
            }
        }
    }
}
