@use '@angular/material' as mat;
@mixin app-select-persons-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-select-persons {
        table tr th[mat-header-cell] {
            background: mat.get-color-from-palette($background, background);
        }
    }
}
