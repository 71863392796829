@use '@angular/material' as mat;
@mixin form-field-theme($theme) {
    $background: map-get($theme, background);

    .hover-form {
        .mat-form-field-flex:hover {
            background-color: mat.get-color-from-palette($background, hover);
        }
    }
}
