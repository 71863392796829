@mixin app-filter-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-filter {
        div.date {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}
