@use '@angular/material' as mat;
@mixin app-e-invoice-allowance-charge-view-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-e-invoice-allowance-charge-view {
        .borders,
        .borders-column,
        .borders-column.borders-column.borders-column.borders-column.borders-column > * {
            border-color: mat.get-color-from-palette($foreground, secondary-text);
        }
    }
}
