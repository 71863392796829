@use '@angular/material' as mat;
@mixin detail-partner-theme($theme) {
    $primary: map-get($theme, primary);

    app-detail-partner {
        a.link {
            color: mat.get-color-from-palette($primary, default-contrast);
        }
    }
}
