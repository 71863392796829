@use '@angular/material' as mat;
@mixin app-e-invoice-view-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-e-invoice-view {
        .grid-borders.grid-borders,
        .grid-borders.grid-borders.grid-borders.grid-borders > *,
        .borders,
        .borders-column,
        .borders-column.borders-column.borders-column.borders-column.borders-column > *,
        .borders-row,
        .borders-row.borders-row.borders-row.borders-row > * {
            border-color: mat.get-color-from-palette($foreground, secondary-text);
        }
        .mat-table td,
        .mat-table th {
            color: mat.get-color-from-palette($foreground, text);
        }
    }
}
