@use '@angular/material' as mat;
@mixin app-comment-card-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    $msg-primary: #d9fdd3;
    $msg-primary-dark: #005c4b;

    app-comment-card {
        mat-card.comment-card.comment-card.comment-card {
            @if map-get($theme, is-dark) {
                background-color: rgb(98 98 98);
                &.its-me {
                    background-color: $msg-primary-dark;
                }
            } @else {
                background-color: rgb(249 249 249);
                &.its-me {
                    background-color: $msg-primary;
                }
            }
        }
    }
}
