@use '@angular/material' as mat;
@mixin app-start-process-dialog-theme($theme) {
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    app-start-process-dialog {
        .selected-files-list {
            border-color: mat.get-color-from-palette($foreground, divider);
        }
    }
}
