@use '@angular/material' as mat;
@mixin app-video-list-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    //app-new{
    //    .hover-form {
    //        &:hover {
    //            background-color: mat-color($background, hover);
    //        }
    //    }
    //}
    //app-detail{
    //    .hover-form {
    //        &:hover {
    //            background-color: mat-color($background, hover);
    //        }
    //    }
    //}
    app-videodokumentation-list {
        section.wrapper {
            background-color: mat.get-color-from-palette($background, background);
            color: mat.get-color-from-palette($foreground, text);
        }

        header.header {
            background-color: mat.get-color-from-palette($primary, darker);
            color: mat.get-color-from-palette($primary, darker-contrast);°

            button.clear-search {
                background-color: mat.get-color-from-palette($primary, darker);
                color: mat.get-color-from-palette($primary, darker-contrast);

                &:hover {
                    background-color: mat.get-color-from-palette($primary);
                }
            }
        }

        .clickable-list-container:before {
            border-right-color: mat.get-color-from-palette($foreground, divider);
        }

        a.active {
            background-color: mat.get-color-from-palette($background, selected-button);
        }
    }
}
