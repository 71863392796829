@mixin app-new-booking-option-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-new-booking-option {
        .highlight.highlight {
            background-color: map-get($background, selected-button);
        }
        .dont-edit.dont-edit,
        .disabled.disabled {
            background-color: map-get($background, disabled-button);
        }
    }
}
