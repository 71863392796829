@import "app-clickable-background";
@mixin clickable-mat-row-theme($theme) {
    $background: map-get($theme, background);
    $isDarkTheme: map-get($theme, is-dark);

    .clickable-mat-row {
        &:hover {
            cursor: pointer;
        }

        &:hover,
        &:active,
        &:focus,
        &.active {
            outline: none;
            background-color: map-get($background, hover);
        }
        @include app-background-colors-for-clickable($isDarkTheme);
    }
}
