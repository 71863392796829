@use '@angular/material' as mat;
@mixin app-arl-table-theme($theme) {
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    app-arl-table {
        tr.element-row:not(.expanded-row):hover {
            background: whitesmoke;
        }

        tr.element-row:not(.expanded-row):active {
            background: #efefef;
        }
        .add-button-list {
            background: map-get($background, card);
        }
    }
}
