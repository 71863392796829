@use '@angular/material' as mat;
@mixin app-email-client-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-email-client {
        .email-list .email-list-entry.current {
            background:  mat.get-color-from-palette($background, app-bar);
        }

        .nav-bar {
            background-color: map-get($background, card);
        }
        .search-bar-header {
            background-color: mat.get-color-from-palette($primary, darker);
            color: mat.get-color-from-palette($primary, darker-contrast);
        }
        .search-bar-clear-search {
            background-color: mat.get-color-from-palette($primary, darker);
            color: mat.get-color-from-palette($primary, darker-contrast);

            &:hover {
                background-color: mat.get-color-from-palette($primary);
            }
        }
        .email-preview {
            .email-contact-link {
                color: mat.get-color-from-palette($foreground, text);
            }

            .email-card {
                border-bottom-color: mat.get-color-from-palette($primary, darker-contrast);
            }
            .email-iframe {
                color: mat.get-color-from-palette($primary, darker-contrast);
            }
        }

        .sub-elements {
            background:  mat.get-color-from-palette($background, app-bar);
        }
        .nav-tree {
            .mat-list-item-content {
                border-top: 1px solid mat.get-color-from-palette($foreground, divider);
            }
        }
        .email-list-entry {
            border-bottom: mat.get-color-from-palette($foreground, divider) 1px solid;
        }
    }
}

