@use '@angular/material' as mat;
@mixin app-expansion-panel-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);


    app-expansion-panel {
        .scrollable .top-shadow {
            box-shadow: 0 0 20px 0 mat.get-color-from-palette($foreground, elevation);
        }
        .scrollable .bottom-shadow {
            box-shadow: 0 0 20px 0 mat.get-color-from-palette($foreground, elevation);
        }
    }
}
