@use '@angular/material' as mat;
@mixin license-settings-theme($theme) {
    $warn: map-get($theme, warn);
    $primary: map-get($theme, foreground);

    app-license-settings {
        .bottom-border {
            border-bottom: 1px dashed map-get($primary, divider);
        }
        .highlighted-button {
            color: map-get($primary, default);
            background-color: map-get($primary, default);
        }
        .inactive-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 10009;
            background-color: black;
            opacity: 0.8;

            .inactive-text {
                position: absolute;
                top: 50%;
                left: 50%;
                font-size: 3rem;
                color: white;
                transform: translate(-50%, -50%);
            }
        }
        .close-button {
            color: mat.get-color-from-palette($primary);
        }
    }
}
