@mixin event-types-selector-card-theme($theme) {
    $warn: map-get($theme, warn);

    app-event-types-selector-card {
        .delete-icon {
            background-color: map-get($warn, default);
            color: map-get($warn, default-contrast);
        }
    }
}
