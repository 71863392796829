// Grundlegendes Theming für alle Buttonarten, wird für einzelne Arten erweitert
@import "base";
@mixin _app-button-base-theme($default, $contrast) {
    $activeDarken: 30%;
    $shaderDarken: 19%;

    background-color: $default;
    color: $contrast;

    //&:focus-visible, todo einkommentieren sobald safari focus-visible unterstützt
    &:hover,
    &.app-button-active,
    &.app-button-inverted {
        background-color: $contrast;
        color: $default;
    }

    //&:focus-visible, todo einkommentieren sobald safari focus-visible unterstützt
    &.app-button-active {
        box-shadow: inset 0 0 0.75rem darken($contrast, $activeDarken);
    }

    &.app-button-inverted {
        //&:focus-visible, todo einkommentieren sobald safari focus-visible unterstützt
        &:hover,
        &.app-button-active {
            border-color: $contrast;
            color: $contrast;
        }

        //&:focus-visible, todo einkommentieren sobald safari focus-visible unterstützt
        &.app-button-active {
            background-color: darken($default, $shaderDarken);
            box-shadow: inset 0 0 0.75rem darken($default, $activeDarken);
        }

        &:hover {
            background-color: $default;
        }
    }

    // Doppelter Selektor um obige Styles zu überschreiben
    &[disabled][disabled] {
        background-color: $app-color-disabled-button;
        border-color: $app-color-disabled-button;
        color: white;
    }
}

@mixin _app-button-and-app-round-button-theme($default, $contrast) {
    @include _app-button-base-theme($default, $contrast);

    border-color: $default;
}

@mixin _app-stroked-round-button-theme($default, $contrast) {
    @include _app-button-base-theme($default, $contrast);

    border-color: $contrast;

    &.app-button-inverted {
        border-color: $default;
    }
}

// $selector ist ein optionaler String für manuelles Theming
@mixin app-button-theme($theme, $selector: '') {
    $primary: map-get($theme, primary);

    $default: map-get($primary, default);
    $default-contrast: map-get($primary, default-contrast);

    $lighter: map-get($primary, lighter);
    $lighter-contrast: map-get($primary, lighter-contrast);

    // $selector wird zwei Mal hinzugefügt für höhere Spezifität als das normale Theming

    .app-button#{$selector}#{$selector},
    .app-round-button#{$selector}#{$selector} {
        @include _app-button-and-app-round-button-theme($default, $default-contrast);

        &.app-button-lighter {
            @include _app-button-and-app-round-button-theme($lighter, $lighter-contrast);
        }
    }

    .app-stroked-round-button#{$selector}#{$selector} {
        @include _app-stroked-round-button-theme($default, $default-contrast);

        &.app-button-lighter {
            @include _app-stroked-round-button-theme($lighter, $lighter-contrast);
        }
    }
}

@include app-button-theme(
    (
        primary: (
            default: red,
            default-contrast: white,
            lighter: red,
            lighter-contrast: white,
        ),
    ),
    '.app-button-color-red.app-button-color-red'
);

@include app-button-theme(
    (
        primary: (
            default: gold,
            default-contrast: white,
            lighter: gold,
            lighter-contrast: white,
        ),
    ),
    '.app-button-color-highlighted.app-button-color-highlighted'
);
@include app-button-theme(
        (
            primary: (
                default: green,
                default-contrast: white,
                lighter: green,
                lighter-contrast: white,
            ),
        ),
        '.app-button-color-green.app-button-color-green'
);
@include app-button-theme(
        (
            primary: (
                default: #757575,
                default-contrast: white,
                lighter: #afafaf,
                lighter-contrast: white,
            ),
        ),
        '.app-button-color-cancel.app-button-color-cancel'
);
