@use '@angular/material' as mat;
@mixin app-commission-overview-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-commission-overview {
        .alex-sein-buntes-feld {
            background-color: mat.get-color-from-palette($primary);
        }

        .btgb-table-wrapper th:first-child,
        .btgb-table-wrapper td:first-child {
            background-color: mat.get-color-from-palette($background, card);
            border-right-color: mat.get-color-from-palette($foreground, divider);
        }
        .btgb-table-wrapper .color-dot {
            border-color: mat.get-color-from-palette($background, unselected-chip);
            &::after {
                border-color: mat.get-color-from-palette($background, card);
            }
        }
        .btgb-table-wrapper th:last-child {
            color: mat.get-color-from-palette($primary);
            border-color: mat.get-color-from-palette($primary);
        }
        .timeline {
            border-color: mat.get-color-from-palette($foreground, divider);
        }
        .timeline-event-card {
            border-color: mat.get-color-from-palette($foreground, disabled);
            &:hover {
                background-color: mat.get-color-from-palette($foreground, disabled);
                color: white;
                cursor: pointer;
            }
        }
    }
}
