// color-plates
// custom http://mcg.mbitson.com/ da kann man paletten bauen
// default https://material.io/tools/color

$c2c-palette: (
    50 : #fef2e5,
    100 : #fbdfbe,
    200 : #f9c992,
    300 : #f7b366,
    400 : #f5a346,
    500 : #f39325,
    600 : #f18b21,
    700 : #ef801b,
    800 : #ed7616,
    900 : #ea640d,
    A100 : #ffffff,
    A200 : #ffece2,
    A400 : #ffcbaf,
    A700 : #ffba96,
    contrast: (
        50: #ffffff,
        100: #ffffff,
        200: #ffffff,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #000000,
        A100: #ffffff,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    )
);

$obligo-palette: (
    50: #ffefe2,
    100: #ffd8b6,
    200: #ffbe85,
    300: #ffa354,
    400: #ff902f,
    500: #ff7c0a,
    600: #ff7409,
    700: #ff6907,
    800: #ff5f05,
    900: #ff4c03,
    A100: #ffffff,
    A200: #fff5f2,
    A400: #ffcebf,
    A700: #ffbba6,
    contrast: (
        50: #ffffff,
        100: #ffffff,
        200: #ffffff,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #000000,
        A100: #ffffff,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    ),
);
$zert-palette: (
    50: #f5f5f5,
    100: #e6e6e6,
    200: #d5d5d5,
    300: #c4c4c4,
    400: #b7b7b7,
    500: #aaaaaa,
    600: #a3a3a3,
    700: #999999,
    800: #909090,
    900: #7f7f7f,
    A100: #ffffff,
    A200: #fbdddd,
    A400: #ffa7a7,
    A700: #ff8d8d,
    contrast: (
        50: #ffffff,
        100: #ffffff,
        200: #ffffff,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #000000,
        A100: #ffffff,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$kagetec-palette: (
    50 : #e0f0e7,
    100 : #b3dbc4,
    200 : #80c39c,
    300 : #4daa74,
    400 : #269857,
    500 : #008639,
    600 : #007e33,
    700 : #00732c,
    800 : #006924,
    900 : #005617,
    A100 : #88ff9c,
    A200 : #55ff72,
    A400 : #22ff48,
    A700 : #08ff33,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$reports-palette: (
    50: #fbe1e1,
    100: #f5b5b5,
    200: #ee8484,
    300: #e75252,
    400: #e22d2d,
    500: #dd0808,
    600: #d90707,
    700: #d40606,
    800: #cf0404,
    900: #c70202,
    A100: #ffefef,
    A200: #ffbcbc,
    A400: #ff8989,
    A700: #ff6f6f,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #000000,
        A700: #000000,
    )
);
$generated-documents-palette: (
    50: #e5eaf2,
    100: #bfcbdf,
    200: #94a9ca,
    300: #6986b5,
    400: #496ca5,
    500: #295295,
    600: #244b8d,
    700: #1f4182,
    800: #193878,
    900: #0f2867,
    A100: #9cb4ff,
    A200: #698eff,
    A400: #3667ff,
    A700: #1c54ff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$email-palette: (
    50: #fdf7ea,
    100: #fbebc9,
    200: #f9dea6,
    300: #f6d182,
    400: #f4c767,
    500: #f2bd4c,
    600: #f0b745,
    700: #eeae3c,
    800: #eca633,
    900: #e89824,
    A100: #ffffff,
    A200: #fff8ef,
    A400: #ffe2bc,
    A700: #ffd7a2,
    contrast: (
        50: #ffffff,
        100: #ffffff,
        200: #ffffff,
        300: #ffffff,
        400: #ffffff,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #000000,
        A100: #ffffff,
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    ),
);

$person-palette: (
    50 : #fff8e1,
    100 : #ffecb5,
    200 : #ffe083,
    300 : #ffd451,
    400 : #ffca2c,
    500 : #ffc107,
    600 : #ffbb06,
    700 : #ffb305,
    800 : #ffab04,
    900 : #ff9e02,
    A100 : #ffffff,
    A200 : #fffaf2,
    A400 : #ffe4bf,
    A700 : #ffd9a6,
    contrast: (
        50: rgba(0, 0, 0, 0.87),
        100: rgba(0, 0, 0, 0.87),
        200: rgba(0, 0, 0, 0.87),
        300: rgba(0, 0, 0, 0.87),
        400: rgba(0, 0, 0, 0.87),
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: rgba(0, 0, 0, 0.87),
        A200: #ffffff,
        A400: #ffffff,
        A700: #ffffff,
    )
);
/* For use in src/lib/core/theming/_palette.scss */
$confirm-button-palette: (
    50 : #e5f5e5,
    100 : #bee5be,
    200 : #93d493,
    300 : #68c268,
    400 : #47b547,
    500 : #27a827,
    600 : #23a023,
    700 : #1d971d,
    800 : #178d17,
    900 : #0e7d0e,
    A100 : #aeffae,
    A200 : #7bff7b,
    A400 : #48ff48,
    A700 : #2fff2f,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$commission-palette: (
    50 : #f1f5f5,
    100 : #dde6e7,
    200 : #c6d5d7,
    300 : #afc4c7,
    400 : #9db7bb,
    500 : #8caaaf,
    600 : #84a3a8,
    700 : #79999f,
    800 : #6f9096,
    900 : #5c7f86,
    A100 : #f3fdff,
    A200 : #c0f5ff,
    A400 : #8dedff,
    A700 : #74e9ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$tsb-palette: (
    50 : #e4eef7,
    100 : #bcd5ec,
    200 : #8fb9df,
    300 : #629dd2,
    400 : #4088c8,
    500 : #1e73be,
    600 : #1a6bb8,
    700 : #1660af,
    800 : #1256a7,
    900 : #0a4399,
    A100 : #c7daff,
    A200 : #94b9ff,
    A400 : #6198ff,
    A700 : #4787ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
$yust-infra-palette: (
    50 : #e0f4fb,
    100 : #b3e5f5,
    200 : #80d3ef,
    300 : #4dc1e8,
    400 : #26b4e3,
    500 : #00a7de,
    600 : #009fda,
    700 : #0096d5,
    800 : #008cd1,
    900 : #007cc8,
    A100 : #f1f9ff,
    A200 : #bee2ff,
    A400 : #8bccff,
    A700 : #71c1ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);
