@use '@angular/material' as mat;
@mixin app-map-dialog-theme($theme) {
    $background: map-get($theme, background);

    app-map-dialog {
        // Spezifischer Selektor, damit keine Unterelemente ungewollt überschrieben werden
        > .buttons {
            background-color: mat.get-color-from-palette($background, card);
        }
    }
}
