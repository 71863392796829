@use '@angular/material' as mat;

@mixin app-file-order-dialog-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);

    app-file-order-dialog {
        .list-item {
            background: mat.get-color-from-palette($background, card);
        }
    }
}
