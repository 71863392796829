// Styling, das sowohl die globalen als auch die kundenspezifischen Statistiken betrifft
@use '@angular/material' as mat;
@import '../../dave-utils-module/dave-shared-components-module/styles/base';

@mixin statistics-mixin {
    .scrollbar {
        height: 100%;
        z-index: 1;
    }

    .content {
        margin: 0 1rem;
    }

    .centered {
        text-align: center;
    }

    .chart-cards {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .chart-card {
        margin: 1rem;
    }

    .chart {
        width: 28rem;
        height: 25rem;

        @media #{$mobile-query} {
            width: 16rem;
            height: 14rem;
        }
    }
}

@mixin statistics-theming-mixin($theme) {
    $background: map-get($theme, background);
    .chart-card {
        //background-color: rgba(255, 255, 255, 0.93);
        $color: mat.get-color-from-palette($background, card);
        background-color: rgba(red($color), green($color), blue($color) , 0.93);
    }
}
