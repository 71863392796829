@use '@angular/material' as mat;
@mixin app-ck-editor-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-ck-editor {
        --ck-color-base-foreground: #fafafa;
        --ck-color-base-background: #fff;
        --ck-color-base-border: #ccced1;
        --ck-color-base-action: #53a336;
        --ck-color-base-focus: #6cb5f9;
        --ck-color-base-text: #333;
        --ck-color-base-active: #2977ff;
        --ck-color-base-active-focus: #0d65ff;
        --ck-color-base-error: #db3700;


        --ck-color-base-background: mat.get-color-from-palette($background, card);
    }
}
