@use '@angular/material' as mat;
@import 'base';

@mixin header-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);

    app-header {
        background-color: mat.get-color-from-palette($background, app-bar);
        @media #{$mobile-query} {
            background-color: mat.get-color-from-palette($primary);
            color: mat.get-color-from-palette($primary, default-contrast);
            .spinner-wrapper .mat-progress-spinner circle,
            .mat-spinner circle {
                stroke: mat.get-color-from-palette($primary, default-contrast);
            }
        }
    }
}
