@use '@angular/material' as mat;
@mixin app-detail-piecework-theme($theme) {
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    app-detail-piecework {
        main {
            background-color: mat.get-color-from-palette($primary, default);
            color: mat.get-color-from-palette($primary, default-contrast);
            mat-ink-bar.mat-ink-bar.mat-ink-bar.mat-ink-bar {
                background-color: mat.get-color-from-palette($primary, default-contrast);
            }
            .mat-list-base .mat-list-item {
                color: mat.get-color-from-palette($primary, default-contrast) !important;
            }
            .mat-tab-label {
                color: mat.get-color-from-palette($primary, default-contrast) !important;
            }
        }
        header {
            background-color: mat.get-color-from-palette($primary, darker);
            color: mat.get-color-from-palette($primary, darker-contrast);
        }
        mat-expansion-panel.primary-background {
            background-color: mat.get-color-from-palette($primary, default);
            color: mat.get-color-from-palette($primary, default-contrast);
        }
    }
}
