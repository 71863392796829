@use '@angular/material' as mat;
@import 'base';

@mixin home-dashboard-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-home-dashboard {
        .welcome-message,
        .header {
            .headline {
                color: mat.get-color-from-palette($primary, lighter-contrast);
            }
        }
        app-commission-dashboard {
            header.header {
                color: mat.get-color-from-palette($foreground, text);
            }
        }
        .dave-home-dashboard-background-image {
            background-color: mat.get-color-from-palette($primary);
            color: mat.get-color-from-palette($primary, default-contrast);
        }

        //@media #{$mobile-query} {
            .mobile-wrapper {
                background-color: mat.get-color-from-palette($background, background);
            }
        //}
        .widget:not(.transparent) {
            background-color: mat.get-color-from-palette($background, card);

            &.weather-mobile {
                color: mat.get-color-from-palette($primary, lighter-contrast);
            }
            &.weather-desktop {
                color: mat.get-color-from-palette($primary, lighter-contrast);
            }
        }
    }
}
