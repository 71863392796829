// $primary-color: rgb(200, 38, 15);
// $secondary-color: white;
// $background-color: #e9f0f5;
// $secondary-background-color: rgb(200, 50, 50);
// $primary-dark: rgb(160, 25, 10);
// $highlight-color: gold;
// $headline-color: black;
// $text-color: black;

// steht auch in /src/app/dave-utils-module/dave-shared-components-module/services/breakpoint-observer.service.ts
$mobile-query: "(max-width: 819px)";
$small-query: "(max-width: 1200px)";
$desktop-query: "(min-width: 820px)";
$touch-query: "(hover: none)";
// media-queries von material https://material.angular.io/cdk/layout/overview https://m1.material.io/layout/responsive-ui.html#responsive-ui-breakpoints
$MatQueryXSmall:	"(max-width: 599.98px)";
$MatQuerySmall:	"(min-width: 600px) and (max-width: 959.98px)";
$MatQueryMedium:	"(min-width: 960px) and (max-width: 1279.98px)";
$MatQueryLarge:	"(min-width: 1280px) and (max-width: 1919.98px)";
$MatQueryXLarge:	"(min-width: 1920px)";
$MatQueryHandset:	"(max-width: 599.98px) and (orientation: portrait), (max-width: 959.98px) and (orientation: landscape)";
$MatQueryTablet:	"(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait), (min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)";
$MatQueryWeb:	"(min-width: 840px) and (orientation: portrait), (min-width: 1280px) and (orientation: landscape)";
$MatQueryHandsetPortrait:	"(max-width: 599.98px) and (orientation: portrait)";
$MatQueryTabletPortrait:	"(min-width: 600px) and (max-width: 839.98px) and (orientation: portrait)";
$MatQueryWebPortrait:	"(min-width: 840px) and (orientation: portrait)";
$MatQueryHandsetLandscape:	"(max-width: 959.98px) and (orientation: landscape)";
$MatQueryTabletLandscape:	"(min-width: 960px) and (max-width: 1279.98px) and (orientation: landscape)";
$MatQueryWebLandscape:	"(min-width: 1280px) and (orientation: landscape)";

$app-loading-cursor: wait;

// header-height steht auch in helper.ts
$header-height: 3.5rem;

$default-space: .5rem;

$component-dashboard-width-main: 75%;
$component-dashboard-width-sidebar: 25%;

$home-dashboard-grid-desktop-layout: "header  sidebar" "main    sidebar";
$home-dashboard-grid-desktop-columns: 75% 25%;
$home-dashboard-grid-desktop-rows: 40% 60%;

$home-dashboard-grid-mobile-layout: "header" "main";
$home-dashboard-grid-mobile-columns: 100%;
$home-dashboard-grid-mobile-rows: 30% 70%;

$detail-grid-desktop-rows: 25% 75%;
$detail-grid-mobile-rows: 25% 75%;

$detail-height-desktop-header: 3.5rem;
$detail-height-desktop-body: calc(100% - #{$detail-height-desktop-header});
$detail-height-mobile-header: 3.5rem;
$detail-height-mobile-body: calc(100% - #{$detail-height-mobile-header});

$example-profile-image: url("https://material.angular.io/assets/img/examples/shiba1.jpg");

$widget-border-radius: 0.5rem;

$image-border-radius: 0.25rem;
$app-round-button-size: 2.5em;

$unseen-color: #116cd5;
$unseen-color-accent: #fff;

$app-color-bookmark: gold;

$app-color-event: rgb(3, 169, 244);
$app-color-time-tracking: rgb(126, 87, 194);
$app-color-approved: rgb(0, 255, 0);
$app-color-declined: rgb(255, 78, 78);

$app-color-late: rgb(255, 0, 0);

$app-color-invoice-verrechnet: $app-color-approved;
$app-color-invoice-booked:rgb(255, 255, 0);
$app-color-invoice-storniert: rgb(0, 0, 0);
$app-color-invoice-approved: rgb(68, 170, 34); //'#44aa22'

$app-color-commission-open: rgb(255, 162, 62);
$app-color-commission-in-progress: rgb(255, 255, 0);
$app-color-commission-freigabe: rgb(205, 16, 118);
$app-color-commission-abgerechnet: rgb(101, 137, 255);
$app-color-commission-rechnung-gestellt: rgb(135, 223, 255);
$app-color-commission-abgeschlossen: $app-color-approved;

$app-color-disabled-button: #e5e7e6;

$card-content-box-shadow: inset 0 1.5rem 1rem -1.75rem black, inset 0 -1.5rem 1rem -1.75rem black;



// todo in theme auslagern
$ready-color: #85cc6f;
$commissioned-color: orange;

$progress-bar-ready-color: $ready-color;
$progress-bar-to-much-color: #d30000;
$progress-bar-empty-color: darkgray;

