@use '@angular/material' as mat;
@mixin timeline-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-timeline {
        .timeline:before {
            background-color: mat.get-color-from-palette($primary);
        }

        .filter {
            background-color: mat.get-color-from-palette($background, card);

            h2 {
                color: mat.get-color-from-palette($primary);
            }
            h3 {
                color: mat.get-color-from-palette($foreground, text);
            }
        }

        .event-icon {
            box-shadow: 0 0 0 2px mat.get-color-from-palette($primary);
            background-color: mat.get-color-from-palette($background, card);
            color: mat.get-color-from-palette($foreground, text);

            &:hover,
            &:focus {
                color: mat.get-color-from-palette($primary);

                fa-icon {
                    color: mat.get-color-from-palette($primary);
                }
            }
        }

        .header {
            background-color: mat.get-color-from-palette($primary);
        }

        .annotation-overlay {
            color: mat.get-color-from-palette($primary, default-contrast);
        }

        .annotation-menu {
            color: mat.get-color-from-palette($primary, default-contrast);

            &:hover {
                color: mat.get-color-from-palette($primary);
            }
        }

        .annotation-content {
            background-color: mat.get-color-from-palette($primary, darker);
        }

        .close-button:hover {
            background-color: mat.get-color-from-palette($primary, darker);
        }

        .annotation-tab {
            &:hover .annotation-name {
                background-color: mat.get-color-from-palette($primary, default-contrast);
                color: mat.get-color-from-palette($primary);
            }

            &.clicked .annotation-name {
                background-color: mat.get-color-from-palette($primary, default-contrast);
                border-color: mat.get-color-from-palette($primary);
                color: mat.get-color-from-palette($primary);
            }
        }

        .annotation-name {
            background-color: mat.get-color-from-palette($primary, darker);
            color: mat.get-color-from-palette($primary, default-contrast);
        }

        .filter-apply {
            background-color: mat.get-color-from-palette($primary);
            border-color: mat.get-color-from-palette($primary, default-contrast);
            color: mat.get-color-from-palette($primary, default-contrast);

            &:hover {
                background-color: mat.get-color-from-palette($primary, default-contrast);
                border-color: mat.get-color-from-palette($primary);
                color: mat.get-color-from-palette($primary);
            }
        }

        .new-year {
            box-shadow: 0 0 0 2px mat.get-color-from-palette($primary);
            background-color: mat.get-color-from-palette($background, background);
            color: mat.get-color-from-palette($foreground, text);
        }

        .no-displayed-events {
            color: mat.get-color-from-palette($primary, default-contrast);
        }

        .clickable-list-item-content fa-icon {
            color: mat.get-color-from-palette($primary);
        }

        .list-day:hover,
        .list-day:focus {
            color: mat.get-color-from-palette($primary);

            fa-icon {
                color: mat.get-color-from-palette($primary);
            }
        }

        .clickable-list-container {
            background-color: mat.get-color-from-palette($background, background);
            .no-displayed-events {
                color: mat.get-color-from-palette($primary);
            }
        }
    }
}
