@use '@angular/material' as mat;

@mixin app-person-list-entry-theme($theme) {
    $foreground: map-get($theme, foreground);

    app-person-list-entry {
        a.link {
            color: mat.get-color-from-palette($foreground, text);
        }
    }
}
