@use '@angular/material' as mat;
@mixin app-event-card-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);

    app-event-card {
        .primary {
            color: mat.get-color-from-palette($primary);;
        }
        button[mat-icon-button].expansion-initiator:hover {
            background: mat.get-color-from-palette($background, hover);
        }
    }
}
