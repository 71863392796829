@use '@angular/material' as mat;
@mixin app-material-for-warehouse-movement-list-theme($theme) {
    $foreground: map-get($theme, foreground);

    app-material-for-warehouse-movement-list {
        .border-color.green {
            border-color: #8eff96; // todo in theme einbauen
        }
        .border-color.yellow {
            border-color: #fef9a2; // todo in theme einbauen
        }
        .border-color.red {
            border-color: #ff8d8d; // todo in theme einbauen
        }
        .color.green {
            color: #8eff96; // todo in theme einbauen
        }
        .color.yellow {
            color: #fef9a2; // todo in theme einbauen
        }
        .color.red {
            color: #ff8d8d; // todo in theme einbauen
        }
        .custom-grid.disabled,
        .color.disabled {
            border-color: mat.get-color-from-palette($foreground, disabled-text);
            color: mat.get-color-from-palette($foreground, disabled-text);
        }
        .dont-disable {
            color: mat.get-color-from-palette($foreground, text);
        }
    }
}
