@use '@angular/material' as mat;
@mixin app-root-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-root {
        background-color: mat.get-color-from-palette($background, background);
        color: mat.get-color-from-palette($foreground, text);
    }
}
