app-textarea.fill {
    height: 100%;

    * {
        height: 100%;
        box-sizing: border-box;
    }

    textarea {
        resize: none;
        min-height: 5rem;
    }
}
