@use '@angular/material' as mat;
@mixin app-contact-list-theme($theme) {
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    app-contact-list {
        .darker-background.darker-background {
            background: mat.get-color-from-palette($background, selected-button);
            &:hover {
                background: mat.get-color-from-palette($background, selected-disabled-button);
            }
        }
    }
}