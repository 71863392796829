@use '@angular/material' as mat;
@mixin profile-template-theme($theme) {
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);

    app-profile-template {
        .card {
            &::before {
                background-color: mat.get-color-from-palette($primary, darker);
            }
        }

        a.link {
            color: mat.get-color-from-palette($foreground, text);
        }
    }
}
