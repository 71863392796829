@use '@angular/material' as mat;
@mixin detail-customer-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);

    //app-new-customer{
    //    .hover-form{
    //        &:hover {
    //            background-color: mat-color($background, hover);
    //        }
    //    }
    //}
    app-detail-customer {
        //    .hover-form {
        //        &:hover {
        //            background-color: mat-color($background, hover);
        //        }
        //}
        .files-wrapper {
            .file {
                border: 1px solid mat.get-color-from-palette($background, status-bar);

                &:hover {
                    background-color: mat.get-color-from-palette($background, status-bar);
                }

                .image-wrapper {
                    background-color: mat.get-color-from-palette($background, background);
                }

                .fileName {
                    color: mat.get-color-from-palette($primary);

                    &:hover {
                        color: mat.get-color-from-palette($primary, lighter);
                    }
                }

                .editing {
                    color: white;
                    background-color: mat.get-color-from-palette($warn);

                    &:hover {
                        background-color: mat.get-color-from-palette($warn, lighter);
                        color: mat.get-color-from-palette($accent);
                    }
                }
            }
        }
    }
}
