@mixin app-select-folder-dialog-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-select-folder-dialog {
        .explorer-tree-node:hover, .list-item:hover {
            background-color: map-get($background, hover);
        }
        .explorer-tree-node.selected {
            background-color: map-get($background, selected-button);
        }
        .explorer-tree-node.disabled-node {
            color: map-get($foreground, disabled-button);
        }
    }
}
