@use '@angular/material' as mat;
@mixin login-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-login {
        a {
            color: mat.get-color-from-palette($primary);
        }
        .wrapper {
            background-color: mat.get-color-from-palette($background, background);
        }
        .formDialog h2 {
            color: mat.get-color-from-palette($foreground, text);;
        }
    }
}
