@use '@angular/material' as mat;
@mixin app-side-nav-bar-theme($theme) {
    $primary: map-get($theme, primary);
    $accent: map-get($theme, accent);
    $warn: map-get($theme, warn);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-side-nav-bar {
        background-color: mat.get-color-from-palette($background, app-bar);
        //background-color: mat.get-color-from-palette($primary);
        //color: mat.get-color-from-palette($primary, default-contrast);
        [mat-list-item].is-active {
            //background-color: mat.get-color-from-palette($primary, lighter);
            background-color: mat.get-color-from-palette($background, hover);
            border-left-color: mat.get-color-from-palette($primary);
        }
        mat-nav-list {
            border-right-color: mat.get-color-from-palette($foreground, divider);
        }
    }
    .dave-inc-bar {
        background-color: mat.get-color-from-palette($primary);
    }
}
