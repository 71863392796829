@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    $color-config: mat.get-color-config($theme);

    $background: map.get($color-config, background);
    $foreground: map.get($color-config, foreground);

    app-mat-menu-header {
        background: mat.get-color-from-palette($background, card);
        color: mat.get-color-from-palette($foreground, text),
    }
}

@mixin typography($theme) {
    // Get the typography config from the theme.
    $typography-config: mat.get-typography-config($theme);
    app-mat-menu-header {
        font: {
            family: mat.font-family($typography-config, body-1);
            size: mat.font-size($typography-config, body-1);
            weight: mat.font-weight($typography-config, body-1);
        }
    }
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }

    $typography-config: mat.get-typography-config($theme);
    @if $typography-config != null {
        @include typography($theme);
    }
}
