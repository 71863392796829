@use '../../../../../node_modules/@angular/material/index' as mat;
@mixin app-pseudo-step-header-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-pseudo-step-header {
        .wrapper-button.active {
            border-color: mat.get-color-from-palette($primary, default);
        }
        .image-wrapper {
            border-color: mat.get-color-from-palette($foreground, disabled);
        }
        .wrapper-button.mat-button-disabled[disabled] {
            color: mat.get-color-from-palette($foreground, text);
        }
        .wrapper-button.active .image-wrapper {
            background-color: mat.get-color-from-palette($primary, default);
            color: mat.get-color-from-palette($primary, default-contrast);
        }
    }
}
