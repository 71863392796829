@use '@angular/material' as mat;
@import "base";
@import "app-clickable-background";
@mixin clickable-list-theme($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);
    $isDarkTheme: map-get($theme, is-dark);

    .clickable-list-container:before {
        box-shadow: inset 0 1.5rem 1rem -1.75rem mat.get-color-from-palette($foreground, elevation),
            inset 0 -1.5rem 1rem -1.75rem mat.get-color-from-palette($foreground, elevation);
    }

    .clickable-list {
        background-color: mat.get-color-from-palette($background, card);
    }

    .clickable-list-item {
        color: mat.get-color-from-palette($foreground, text);
        position: relative;

        &.is-active,
        &:hover,
        &:active,
        &:focus {
            background-color: mat.get-color-from-palette($background, hover);
        }
        @include app-background-colors-for-clickable($isDarkTheme);
        &.custom-background-color {
            @if var(--custom-background-color) {
                background-color: var(--custom-background-color);
            }
            &.is-active,
            &:hover,
            &:active,
            &:focus {
                @if var(--custom-background-color-hover) {
                    background-color: var(--custom-background-color-hover);
                }
            }
        }
    }
    .disabled-table-entry, .is-highlighted {
        background-color: mat.get-color-from-palette($background, disabled-button) !important;
        color: mat.get-color-from-palette($foreground, disabled-text) !important;
    }
}
