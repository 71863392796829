// Custom Angular Material Styling
@use '@angular/material' as mat;

@use 'mat-menu-header/mat-menu-header.component.theme' as app-mat-menu-header;

@import '@angular/material/theming';
.mat-checkbox-layout {
    white-space: normal !important;
}
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`

/* TODO(mdc-migration): Remove all-legacy-component-typographies once all legacy components are migrated*/
@include mat.all-legacy-component-typographies();
@include mat.all-component-typographies();
/* TODO(mdc-migration): Remove legacy-core once all legacy components are migrated*/
@include mat.legacy-core();
@include mat.core();

// import custom themes
@import 'styles/custom-theme';

// import custom components
@import 'styles/background-image.theme';
@import 'styles/clickable-list.theme';

@import '../dave-utils-module/dave-shared-components-module/components/customer-list/customer-list.component.theme';
@import '../components/partner-list/partner-list.component.theme';
@import '../components/employee-list/employee-list.component.theme';
@import '../components/history/history-side-elements/history-side-elements.component.theme';
@import 'components/header/breadcrumbs/breadcrumbs.component.theme';
@import 'components/header/header.component.theme';
@import 'components/login/login.component.theme';
@import '../dave-utils-module/dave-shared-components-module/components/history-bookmarks/history-bookmarks.component.theme';
@import '../components/dashboard/home-dashboard.component.theme';
@import '../components/logout/logout.component.theme';
@import '../components/detail-views/detail-event/detail-event.component.theme';
@import '../components/detail-views/detail-customer/detail-customer.component.theme';
@import '../dave-commission-module/components/detail-commission/detail-commission.component.theme';
@import '../components/detail-views/detail-partner/detail-partner.component.theme';
@import '../components/history/timeline/timeline.component.theme';
@import '../dave-utils-module/dave-shared-components-module/components/templates/event-types-selector-card/icon-select-dialog/icon-select-dialog.component.theme';
@import '../dave-settings-module/components/license-settings/license-settings.theme';
@import 'components/statistics/customer-statistics/customer-statistics.component.theme';
@import 'components/statistics/global-statistics/global-statistics.component.theme';
@import '../dave-utils-module/dave-shared-components-module/components/detail-views/detail-view-template/detail-view-template.component.theme';
@import '../dave-utils-module/dave-shared-components-module/components/detail-views/profile-template/profile-template.component.theme';
@import '../dave-utils-module/dave-shared-components-module/directives/clickable-mat-row.theme';
@import 'components/search/search-return/search-return.component.theme';
@import '../dave-utils-module/app-button-module/app-button/app-button.component.theme';
@import '../components/history/timeline/timeline-view/timeline-view.component.theme';
@import '../components/templates/event-types-selector-card/event-types-selector-card.component.theme';
@import '../components/new-view/new-event/new-event.component.theme.scss';
@import '../dave-loading/loading.component.theme';
@import '../dave-utils-module/dave-shared-components-module/components/dialogs/map-dialog/map-dialog.component.theme';
@import '../components/statistics/statistics';
@import '../dave-settings-module/components/settings-list/settings-list.component.theme';
@import '../dave-utils-module/dave-shared-components-module/components/templates/list-layout-with-routing/list-layout-with-routing.component.theme';
@import '../dave-dms-module/dave-dms/preview/preview.component.theme';
@import '../dave-dms-module/dave-dms/file-card/file-card.component.theme';
@import '../dave-commission-module/components/commission-list/commission-list.component.theme';
@import '../dave-videodokumentation-module/components/videodokumentation-list/videodokumentaion-list.component.theme';
@import '../dave-email-module/components/email-client.component.theme';
@import '../dave-utils-module/dave-shared-components-module/components/dialogs/support-dialog/support-dialog.component.theme';
@import '../dave-dms-module/dave-dms/image-editor/image-editor.component.theme';
@import '../dave-file-explorer/components/file-explorer/file-explorer.component.theme';
@import '../dave-file-explorer/components/select-folder-dialog/select-folder-dialog.component.theme';
@import '../dave-reports/components/reports-list/reports-list.component.theme';
@import '../dave-reports/components/invoice-editor/invoice-editor.component.theme';
@import '../dave-generated-documents/components/generated-documents-list/generated-documents-list.component.theme';
@import '../dave-list-card/dave-list-card.component.theme';
@import 'styles/form-field.theme';
@import '../dave-utils-module/app-filter-module/app-filter/app-filter.component.theme';
@import '../dave-event-card/components/event-card/event-card.component.theme';
@import '../dave-booking/booking-positions-list/booking-positions-list.component.theme';
@import '../dave-list/components/dave-list/dave-list.component.theme';
@import '../dave-calendar/components/shift-plan/shift-plan.component.theme.scss';
@import '../dave-calendar/components/resource-plan/resource-plan.component.theme';
@import '../dave-contact-book/components/contact-list/contact-list.component.theme.scss';
@import '../dave-person-module/components/select-persons/select-persons.component.theme.scss';
@import '../dave-piecework-calculation/components/detail-piecework/detail-piecework-calculation.component.theme';
@import '../dave-piecework-calculation/components/piecework-demo/piecework-demo.component.theme';
@import 'components/side-nav-bar/side-nav-bar.component.theme';
@import '../app.component.theme';
@import 'dave-commission-module/components/commission-overview/commission-overview.component.theme';
@import 'dave-comments/components/comment-list/comment-list.component.theme';
@import 'dave-comments/components/comment-card/comment-card.component.theme';
@import 'dave-reports/components/new-booking-option/new-booking-option.component.theme';
@import 'dave-time-tracker/components/time-tracker/time-tracker.component.theme';
@import 'person-list-entry/person-list-entry.component.theme';
@import 'expansion-panel/expansion-panel.component.theme';
@import 'ck-editor/ck-editor.component.theme';
@import 'blueprint/components/file-order-dialog/file-order-dialog.component.theme';
@import 'process/components/start-process-dialog/start-process-dialog.component.theme';
@import 'process/components/process-detail-view/components/process-main-view/process-main-view.component.theme';
@import 'components/templates/new-document-view/component/new-document-view.component.theme';
@import 'time-tracker/components/time-tracker-multiple/time-tracker-multiple.component.theme';
@import '../dave-reports/components/arl-calculation/arl-table/arl-table.component.theme';
@import '../pseudo-stepper/components/pseudo-step/pseudo-step-header.component.theme';
@import '../dave-commission-module/components/commission-overview/components/milestone-modal/milestone-modal.component.theme';
@import '../detail-list-template-dialog/export-business-volumes-dialog/export-business-volumes-dialog.theme';
@import '../e-invoice/components/e-invoice-view/e-invoice-view.component.theme';
@import '../e-invoice/components/e-invoice-view/e-invoice-allowance-charge-view/e-invoice-allowance-charge-view.component.theme';
@import 'dave-reports/components/invoice-calculation/invoice-calculation.component.theme';
@import 'warehouse/warehouse-movement/material-for-warehouse-movement-list/material-for-warehouse-movement-list.component.theme';
// ngx-toastr Theming

// bekommt eine "mat-palette" für jeden anzeigbaren Zustand übergeben
@mixin ngx-toastr-theme($success, $error, $theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    .ngx-toastr {
        box-shadow: 0 0 0.5rem rgba(black, 0.25);

        &:hover {
            box-shadow: 0 0 1rem rgba(black, 0.5);
        }

        // Diese konfigurierbaren Klassen werden automatisch an Toasts angehängt, die über die
        // gleichnamigen Funktionen des ToastrServices aufgerufen werden.

        &.toast-success {
            background-color: mat.get-color-from-palette($success);
            color: mat.get-color-from-palette($success, default-contrast);
        }
        &.toast-error {
            background-color: mat.get-color-from-palette($error);
            color: mat.get-color-from-palette($error, default-contrast);
        }
        &.toast-info {
            background-color: mat.get-color-from-palette($background, card);
            color: mat.get-color-from-palette($foreground, text);
        }
    }
}
@mixin ngx-color-picker-theme($theme) {
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    .ngx-mat-colorpicker-content {
        color: mat.get-color-from-palette($foreground, text);
        background-color: mat.get-color-from-palette($background, card);
        &.ngx-mat-colorpicker-content-touch {
            background: transparent;
            border: none;
            box-shadow: none;
        }
    }
}

@mixin green-progress-bar-green($theme) {
    .mat-green {
        @include mat.legacy-progress-bar-color(
            map-merge(
                $theme,
                (
                    primary: mat.define-palette(mat.$light-green-palette, 800),
                )
            )
        );
    }
}
@mixin primary-lighter-mat-button($theme) {
    $primary: map-get($theme, primary);

    .mat-button.mat-primary-lighter,
    .mat-stroked-button.mat-primary-lighter {
        color: mat.get-color-from-palette($primary, lighter);
    }
    .mat-button.mat-primary-lighter:hover,
    .mat-stroked-button.mat-primary-lighter:hover {
        background-color: mat.get-color-from-palette($primary, default-contrast);
    }

    .mat-raised-button.mat-primary-lighter,
    .mat-flat-button.mat-primary-lighter,
    .mat-fab.mat-primary-lighter,
    .mat-mini-fab.mat-primary-lighter {
        color: mat.get-color-from-palette($primary, default-contrast);
        background-color: mat.get-color-from-palette($primary, lighter);
    }

    .mat-icon-button.mat-primary-lighter {
        color: mat.get-color-from-palette($primary, lighter);
    }
}
@mixin color-classes($theme) {
    .app-background-primary {
        background-color: mat.get-color-from-palette(map-get($theme, primary), default);
    }
    .app-background-accent {
        background-color: mat.get-color-from-palette(map-get($theme, accent), default);
    }
    .app-background-warn {
        background-color: mat.get-color-from-palette(map-get($theme, warn), default);
    }
    .app-foreground-primary {
        color: mat.get-color-from-palette(map-get($theme, primary), default);
    }
    .app-foreground-accent {
        color: mat.get-color-from-palette(map-get($theme, accent), default);
    }
    .app-foreground-warn {
        color: mat.get-color-from-palette(map-get($theme, warn), default);
    }
}

// set up custom components
@mixin custom-theming($theme, $with-default-theme: true) {
    @if $with-default-theme {
        /* TODO(mdc-migration): Remove all-legacy-component-colors once all legacy components are migrated*/
        @include mat.all-legacy-component-colors($theme);
        // todo wenn alles zu mdc migriert wurde hier alle Farben includieren, bis dahin nach und nach je component
        //@include mat.all-component-colors($theme);
        @include mat.checkbox-color($theme);
        @include mat.table-color($theme);

        @include app-mat-menu-header.color($theme);
    }
    @include mat.legacy-slide-toggle-color(
            map-merge(
                    $theme,
                    (
                        accent: mat.define-palette(mat.$light-green-palette, 800),
                    )
            )
    );
    @include green-progress-bar-green($theme);
    @include primary-lighter-mat-button($theme);
    @include background-image-theme($theme);
    @include clickable-list-theme($theme);
    @include customer-list-theme($theme);
    @include employee-list-theme($theme);
    @include dashboard-side-elements-theme($theme);
    @include breadcrumbs-theme($theme);
    @include header-theme($theme);
    @include login-theme($theme);
    @include history-bookmarks-theme($theme);
    @include partner-list-theme($theme);
    @include home-dashboard-theme($theme);
    @include logout-theme($theme);
    @include timeline-theme($theme);
    @include detail-event-theme($theme);
    @include detail-customer-theme($theme);
    @include detail-commission-theme($theme);
    @include detail-partner-theme($theme);
    @include icon-select-dialog-theme($theme);
    @include license-settings-theme($theme);
    @include customer-statistics-theme($theme);
    @include global-statistics-theme($theme);
    @include clickable-mat-row-theme($theme);
    @include detail-view-template-theme($theme);
    @include profile-template-theme($theme);
    @include search-return-theme($theme);
    @include app-button-theme($theme);
    @include timeline-view-theme($theme);
    @include event-types-selector-card-theme($theme);
    @include app-loading-theme($theme);
    @include app-map-dialog-theme($theme);
    @include app-settings-list-theme($theme);
    @include list-layout-with-routing-theme($theme);
    @include app-preview-theme($theme);
    @include app-file-card-theme($theme);
    @include app-commission-list-theme($theme);
    @include app-video-list-theme($theme);
    @include app-email-client-theme($theme);
    @include app-support-dialog-theme($theme);
    @include app-image-editor-theme($theme);
    @include app-file-explorer-theme($theme);
    @include app-select-folder-dialog-theme($theme);
    @include reports-list-theme($theme);
    @include generated-documents-list-theme($theme);
    @include app-invoice-editor-theme($theme);
    @include form-field-theme($theme);
    @include app-dave-list-card-theme($theme);
    @include app-filter-theme($theme);
    @include app-event-card-theme($theme);
    @include booking-positions-list-theme($theme);
    @include app-dave-list-theme($theme);
    @include app-export-business-volumes-dialog($theme);
    @include app-contact-list-theme($theme);
    @include app-select-persons-theme($theme);
    @include shift-plan-theme($theme);
    @include app-detail-piecework-theme($theme);
    @include app-piecework-demo-theme($theme);
    @include app-side-nav-bar-theme($theme);
    @include app-root-theme($theme);
    @include app-commission-overview-theme($theme);
    @include app-comment-list-theme($theme);
    @include app-new-booking-option-theme($theme);
    @include ngx-toastr-theme(mat.define-palette(mat.$light-green-palette, 800), mat.define-palette(mat.$red-palette, 800), $theme);
    @include app-resource-plan-theme($theme);
    @include app-time-tracker-theme($theme);
    @include ngx-color-picker-theme($theme);
    @include app-person-list-entry-theme($theme);
    @include app-expansion-panel-theme($theme);
    @include color-classes($theme);
    @include app-ck-editor-theme($theme);
    @include app-comment-card-theme($theme);
    @include app-file-order-dialog-theme($theme);
    @include app-start-process-dialog-theme($theme);
    @include app-process-main-view-theme($theme);
    @include app-new-document-view-component-theme($theme);
    @include app-time-tracker-multiple-theme($theme);
    @include app-arl-table-theme($theme);
    @include app-pseudo-step-header-theme($theme);
    @include app-app-milestone-modal-theme($theme);
    @include app-e-invoice-view-theme($theme);
    @include app-e-invoice-allowance-charge-view-theme($theme);
    @include app-invoice-calculation-theme($theme);
    @include app-material-for-warehouse-movement-list-theme($theme);
}

// set up custom themes

// default theme
.ressource-theme,
.ressource-theme.theme-override.theme-override,
* {
    /* TODO(mdc-migration): Remove all-legacy-component-themes once all legacy components are migrated*/
    @include mat.all-legacy-component-themes($app-theme);
    @include mat.all-component-themes($app-theme);
    @include app-mat-menu-header.theme($app-theme);

    @include custom-theming($app-theme, false);
    &.dark-theme {
        @include custom-theming($app-theme-dark);
        a[href]:not([mat-button], [app-button], [app-round-button], [app-stroked-round-button], [mat-list-item], [mat-menu-item], [role=button]) {
            color: rgb(118, 200, 228);
        }    }
}

// Branding themes
.obligo-theme {
    @include custom-theming($app-theme-brand-obligo);
    &.dark-theme {
        @include custom-theming($app-theme-brand-obligo-dark);
    }
}
.zert-theme {
    @include custom-theming($app-theme-brand-zert);
    &.dark-theme {
        @include custom-theming($app-theme-brand-zert-dark);
    }
}
.kagetec-theme {
    @include custom-theming($app-theme-brand-kagetec);
    &.dark-theme {
        @include custom-theming($app-theme-brand-kagetec-dark);
    }
}
.tsb-theme {
    @include custom-theming($app-theme-brand-tsb);
    &.dark-theme {
        @include custom-theming($app-theme-brand-tsb-dark);
    }
}
.yust-infra-theme {
    @include custom-theming($app-theme-brand-yust-infra);
    &.dark-theme {
        @include custom-theming($app-theme-brand-yust-infra-dark);
    }
}

// custom themes
.history-theme,
.history-theme.theme-override.theme-override {
    @include custom-theming($app-theme-history);
    &.dark-theme {
        @include custom-theming($app-theme-history-dark);
    }
}

.admin-theme,
.admin-theme.theme-override.theme-override {
    @include custom-theming($app-theme-admin);
    &.dark-theme {
        @include custom-theming($app-theme-admin-dark);
    }
}

.stats-theme,
.stats-theme.theme-override.theme-override {
    @include custom-theming($app-theme-stats);
    &.dark-theme {
        @include custom-theming($app-theme-stats-dark);
    }
}
.settings-theme,
.settings-theme.theme-override.theme-override {
    @include custom-theming($app-theme-settings);
    &.dark-theme {
        @include custom-theming($app-theme-settings-dark);
    }
}
.partner-theme,
.partner-theme.theme-override.theme-override {
    @include custom-theming($app-theme-partner);
    &.dark-theme {
        @include custom-theming($app-theme-partner-dark);
    }
}
.user-theme,
.user-theme.theme-override.theme-override {
    @include custom-theming($app-theme-userManagement);
    &.dark-theme {
        @include custom-theming($app-theme-userManagement-dark);
    }
}
.commission-theme,
.commission-theme.theme-override.theme-override {
    @include custom-theming($app-theme-commission);
    &.dark-theme {
        @include custom-theming($app-theme-commission-dark);
    }
}

.docs-theme,
.docs-theme.theme-override.theme-override,
.calendar-theme,
.calendar-theme.theme-override.theme-override {
    @include custom-theming($app-theme-docs);
    &.dark-theme {
        @include custom-theming($app-theme-docs-dark);
    }
}
.dms-theme,
.dms-theme.theme-override.theme-override {
    @include custom-theming($app-theme-dms);
    &.dark-theme {
        @include custom-theming($app-theme-dms-dark);
    }
}
.video-theme,
.video-theme.theme-override.theme-override {
    @include custom-theming($app-theme-video);
    &.dark-theme {
        @include custom-theming($app-theme-video-dark);
    }
}
.email-theme,
.email-theme.theme-override.theme-override {
    @include custom-theming($app-theme-email);
    &.dark-theme {
        @include custom-theming($app-theme-email-dark);
    }
}
.reports-theme,
.reports-theme.theme-override.theme-override {
    @include custom-theming($app-theme-reports);
    &.dark-theme {
        @include custom-theming($app-theme-reports-dark);
    }
}
.generated-documents-theme,
.generated-documents-theme.theme-override.theme-override {
    @include custom-theming($app-theme-generated-documents);
    &.dark-theme {
        @include custom-theming($app-theme-generated-documents-dark);
    }
}
.contacts-theme,
.contacts-theme.theme-override.theme-override {
    @include custom-theming($app-theme-person);
    &.dark-theme {
        @include custom-theming($app-theme-person-dark);
    }
}
.task-theme,
.task-theme.theme-override.theme-override {
    @include custom-theming($app-theme-task);
    &.dark-theme {
        @include custom-theming($app-theme-task-dark);
    }
}
.piecework-theme,
.piecework-theme.theme-override.theme-override {
    @include custom-theming($app-theme-piecework);
    &.dark-theme {
        @include custom-theming($app-theme-piecework-dark);
    }
}


.history-button-wrapper.history-button-wrapper {
    @include app-button-theme($app-theme-history);
}

.video-button-wrapper.video-button-wrapper {
    @include app-button-theme($app-theme-video);
}

.statistics-button-wrapper.statistics-button-wrapper {
    @include app-button-theme($app-theme-stats);
}

.admin-button-wrapper.admin-button-wrapper {
    @include app-button-theme($app-theme-admin);
}
.commission-button-wrapper.commission-button-wrapper {
    @include app-button-theme($app-theme-commission);
}
.docs-button-wrapper.docs-button-wrapper {
    @include app-button-theme($app-theme-docs);
}
.dms-button-wrapper.dms-button-wrapper {
    @include app-button-theme($app-theme-dms);
}
.akkord-button-wrapper.akkord-button-wrapper {
    @include app-button-theme($app-theme-piecework);
}
.cancel-button-wrapper.cancel-button-wrapper {
    @include app-button-theme($app-theme-cancel-button);
}
.confirm-button-wrapper.confirm-button-wrapper {
    @include app-button-theme($app-theme-confirm-button);
}
