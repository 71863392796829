@mixin app-invoice-editor-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-invoice-editor {
        .button-wrapper {
            background-color: map-get($background, card);
        }
    }
}
