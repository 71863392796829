@use '@angular/material' as mat;
@mixin app-app-milestone-modal-theme($theme) {
    $background: map-get($theme, background);

    app-milestone-modal {
        .status-form {
            background: mat.get-color-from-palette($background, card);
        }
    }
}
