@use '@angular/material' as mat;
@mixin app-resource-plan-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-resource-plan {
        .header-colorful {
            background-color: mat.get-color-from-palette($primary, darker) ;
            color: mat.get-color-from-palette($primary, darker-contrast);
            .today-button.today-button {
                border: solid mat.get-color-from-palette($primary, darker-contrast) 1px;
            }
            .swipe-button.swipe-button,.today-button.today-button {
                color: mat.get-color-from-palette($primary, darker-contrast);
                &:hover {
                    background: mat.get-color-from-palette($background, selected-disabled-button);
                }
            }
        }
    }
}
