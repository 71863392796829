@use '@angular/material' as mat;
@mixin app-loading-theme($theme) {
    $background: map-get($theme, background);

    app-loading.solid {
        background-color: mat.get-color-from-palette($background, background);
    }
    app-loading.shade {
        background: rgba(0, 0, 0, 0.15);
    }
}
