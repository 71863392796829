@import "base";


@mixin app-clickable-background($color, $isDarkTheme, $alpha: 0.2) {
     $_color: rgba(red($color), green($color), blue($color), $alpha);
    background-color: $_color;
    &.is-active,
    &:hover,
    &:active,
    &:focus {
        @if ($isDarkTheme) {
            background-color: lighten($_color, 10%);
        } @else {
            background-color: darken($_color, 10%);
        }
    }
}
@mixin app-background-colors-for-clickable($isDarkTheme, $alpha: 0.2) {
    &.app-color-event {
        @include app-clickable-background($app-color-event, $isDarkTheme);
    }
    &.app-color-time-tracking {
        @include app-clickable-background($app-color-time-tracking, $isDarkTheme);
    }
    &.app-color-approved {
        @include app-clickable-background($app-color-approved, $isDarkTheme);
    }
    &.app-color-declined {
        @include app-clickable-background($app-color-declined, $isDarkTheme);
    }
    &.app-color-invoice-verrechnet {
        @include app-clickable-background($app-color-invoice-verrechnet, $isDarkTheme);
    }
    &.app-color-invoice-booked {
        @include app-clickable-background($app-color-invoice-booked, $isDarkTheme);
    }
    &.app-color-late {
        @include app-clickable-background($app-color-late, $isDarkTheme);
    }
    &.app-color-invoice-storniert {
        @include app-clickable-background($app-color-invoice-storniert, $isDarkTheme);
    }
    &.app-color-invoice-approved {
        @include app-clickable-background($app-color-invoice-approved, $isDarkTheme);
    }
    &.app-color-commission-open {
        @include app-clickable-background($app-color-commission-open, $isDarkTheme);
    }
    &.app-color-commission-in-progress {
        @include app-clickable-background($app-color-commission-in-progress, $isDarkTheme);
    }
    &.app-color-commission-freigabe {
        @include app-clickable-background($app-color-commission-freigabe, $isDarkTheme);
    }
    &.app-color-commission-abgerechnet {
        @include app-clickable-background($app-color-commission-abgerechnet, $isDarkTheme);
    }
    &.app-color-commission-rechnung-gestellt {
        @include app-clickable-background($app-color-commission-rechnung-gestellt, $isDarkTheme);
    }
    &.app-color-commission-abgeschlossen {
        @include app-clickable-background($app-color-commission-abgeschlossen, $isDarkTheme);
    }
}
