@use '@angular/material' as mat;
@import "base";
@mixin app-file-explorer-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);

    app-file-explorer {
        .example-container, .content {
            background-color: map-get($background, card);
        }
        .explorer-tree-node:hover,
        .list-item:hover {
            background-color: map-get($background, hover);
        }
        .explorer-tree-node.selected {
            background-color: map-get($background, selected-button);
        }
        .share-icon {
            color: map-get($foreground, hint-text);
        }
        .toggle-view-button {
            background: mat.get-color-from-palette($primary, darker);
            color: mat.get-color-from-palette($primary, darker-contrast);
        }
        .root-button {
            color: mat.get-color-from-palette($background, card);
            fa-icon {
                color: mat.get-color-from-palette($foreground, icon);
            }
        }
        .selected-elements-amount {
            background-color: mat.get-color-from-palette($background, card);
        }
        .back-button{
            .inverted-arrow{
                color: mat.get-color-from-palette($primary, default);
                          }
            &:focus,
            &:hover{
                .inverted-arrow{
                    color: mat.get-color-from-palette($primary, default-contrast);
                              }
            }
            &:disabled {
                .inverted-arrow{
                    color: $app-color-disabled-button;
                }
            }
        }
    }
}
