@mixin search-return-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-search-return {
        background-color: map-get($background, raised-button);
        .available-types {
            background-color: map-get($background, raised-button);
        }
        .data {
            color: map-get($foreground, text);
            background-color: map-get($background, raised-button);

            &:nth-child(2n + 1) {
                background-color: map-get($background, selected-button);
            }

            &:hover {
                background-color: darken(map-get($background, selected-button), 10%);
            }

            .icon-wrapper {
                border-color: map-get($background, selected-button);
            }
        }
    }
}
