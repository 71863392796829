@use '@angular/material' as mat;
@mixin detail-view-template-theme($theme) {
    $primary: map-get($theme, primary);
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    app-detail-view-template {
        background-color: mat.get-color-from-palette($background, background);
        // Spezifischer Selektor, damit keine Unterelemente ungewollt überschrieben werden
        > .buttons {
            background-color: mat.get-color-from-palette($primary);
            color: mat.get-color-from-palette($primary, default-contrast);
        }
        .resizer.resizer.resizer {
            background-color: mat.get-color-from-palette($foreground, divider);
            //background-color: #cbd5e0;
            border-color: mat.get-color-from-palette($background, background);
        }
    }
}
