@import '../dave-utils-module/dave-shared-components-module/styles/base';

.toast-top-right {
    top: calc(0.75rem + #{$header-height} * 2);
    right: 0.75rem;
}

.toast-container {
    pointer-events: none;
    position: fixed;
    z-index: 999999;

    * {
        box-sizing: border-box;
    }

    .ngx-toastr {
        position: relative;
        overflow: hidden;
        margin-bottom: 0.5rem;
        padding: 1rem;
        width: 18.75rem;
        border-radius: 0.25rem;
        cursor: pointer;
        pointer-events: auto;

        .toast-title {
            font-weight: bold;
        }

        .toast-message {
            word-wrap: break-word;
        }
    }
}
