@use '@angular/material' as mat;
@mixin app-image-editor-theme($theme) {

    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    app-image-editor {
        .tui-image-editor-main-container {
            background-color: mat.get-color-from-palette($background, card) !important;
        }
        .tui-image-editor-controls {
            background-color: mat.get-color-from-palette($background, app-bar) !important;
        }
        .tui-image-editor-menu {
            .enabled {
                color: mat.get-color-from-palette($foreground, disabled-button);
            }
            .normal {
                color: mat.get-color-from-palette($foreground, disabled-button);
            }
        }
        .tui-image-editor-container .tui-image-editor-help-menu {
            background-color: mat.get-color-from-palette($background, app-bar);
        }
    }
}
