@mixin appScrollBarStyle($darker: false) {
    scrollbar-width: thin;
    scrollbar-color: rgba(90, 90, 90, 1) rgba(90, 90, 90, 0.5);
    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0);
    }
    &::-webkit-scrollbar-thumb {
        background: rgba(128, 128, 128, 0.5);
        @if $darker == true {
            background: rgba(80, 80, 80, 0.5);
        }
        border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
        background: rgba(128, 128, 128, 1);
        @if $darker == true {
            background: rgba(80, 80, 80, 15);
        }
    }
}
@mixin daveScrollBar($darker: false) {
    @include appScrollBarStyle($darker);
    overflow: auto;
    overflow: overlay;
}
