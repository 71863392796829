@use '@angular/material' as mat;
@mixin app-preview-theme($theme) {
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    app-preview {
        //.hover-form{
        //    &:hover {
        //        background-color: mat-color($background, hover);
        //    }
        //}
        .preview-wrapper {
            border-color: mat.get-color-from-palette($foreground, darker);
        }
        .timeline-item-card {
            background-color: mat.get-color-from-palette($background, card);
        }
    }
}
