@use '@angular/material' as mat;
@mixin app-dave-list-card-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);

    app-dave-list-card {
        .selected-clickable-list-item, .selected-clickable-list-item:hover{
            background: map-get($background, selected-button);
        }
        .card-header {
            background: map-get($background, card);
            box-shadow: 0 0 20px 0 mat.get-color-from-palette($foreground, elevation);
        }
        .bottom-shadow {
            box-shadow: 0 0 20px 0 mat.get-color-from-palette($foreground, elevation);
        }
    }
}
