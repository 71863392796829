@use '@angular/material' as mat;
@mixin app-file-card-theme($theme) {
    $primary: map-get($theme, primary);
    $foreground: map-get($theme, foreground);
    $background: map-get($theme, background);

    app-file-card {
        .inset-box-shadow-left-right.inset-box-shadow-left-right {
            box-shadow:
                inset .5rem 0 .5rem -.5rem mat.get-color-from-palette($primary, default),
                inset -.5rem 0 .5rem -.5rem mat.get-color-from-palette($primary, default);
        }
        .inset-box-shadow-left-right-top.inset-box-shadow-left-right-top {
            box-shadow:
                inset .5rem 0 .5rem -.5rem mat.get-color-from-palette($primary, default),
                inset -.5rem 0 .5rem -.5rem mat.get-color-from-palette($primary, default),
                inset 0 .5rem .5rem -.5rem mat.get-color-from-palette($primary, default);
        }
        .inset-box-shadow.inset-box-shadow {
            box-shadow:
                inset 0 0 .5rem mat.get-color-from-palette($primary, default);
        }
        .no-image,
        .image-container {
            border-color: mat.get-color-from-palette($foreground, darker);
        }
        .image-container {
            background-color: gray; // ToDo: theme verwenden
        }
        .download-button:hover, .delete-button:hover, .event-button:hover, .file-info-button:hover {
            background-color: mat.get-color-from-palette($background, hover);
        }
        .download-button:hover, .event-button:hover {
            color: mat.get-color-from-palette($primary, default);
        }
        .delete-button, .selected-icon, .link-icon {
            color: mat.get-color-from-palette($background, selected-disabled-button);
        }
        .delete-button:hover{
            color: red;
        }
        .timeline-item-card {
            background-color: mat.get-color-from-palette($background, card);
        }
    }
}
