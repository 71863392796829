@use '@angular/material' as mat;
@mixin list-layout-with-routing-theme($theme) {
    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);
    $primary: map-get($theme, primary);
    app-list-layout-with-routing {
        .tab-group {
            background-color: mat.get-color-from-palette($background, background);
        }
        .toggle-view-button {
            background: mat.get-color-from-palette($primary, darker);
            color: mat.get-color-from-palette($primary, darker-contrast);
        }
    }
}
