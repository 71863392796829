@use '@angular/material' as mat;

@mixin app-time-tracker-theme($theme) {
    $primary: map-get($theme, primary);

    app-time-tracker {
        background-color: mat.get-color-from-palette($primary, lighter);
        color: mat.get-color-from-palette($primary, lighter-contrast);
    }
}
