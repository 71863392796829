@import 'styles/background-image';
@import 'styles/ngx-toastr';
@import 'styles/custom-theming-setup';
@import 'components/templates/textarea/textarea-material-override';
@import 'dropzone/dist/min/dropzone.min.css';

@import '@toast-ui/editor/dist/toastui-editor.css';
@import '../node_modules/tui-color-picker/dist/tui-color-picker.css';
@import '../node_modules/tui-image-editor/dist/tui-image-editor.css';

@import "../node_modules/leaflet/dist/leaflet.css";
@import "../node_modules/leaflet.fullscreen/Control.FullScreen.css";

@import 'src/app/styles/syncfusion';

@import 'scrollbar';

@import "@fortawesome/fontawesome-free/css/all.css";

html,
body {
    height: 100%;
    margin: 0;
}
body.inheritCursors * {
    cursor: inherit !important;
}
// Fix für kaputte scroll listen D277-1676, da mann die elemente mit einem Tooltip im DOM nicht identifizieren kann muss hier die matTooltipPosition gesetzt werden
[mattooltipposition], [matripple] {
    touch-action: auto !important;
}

@font-face {
    font-family: 'Century Gothic';
    src: url('assets/fonts/CenturyGothic.eot');
    src: url('assets/fonts/CenturyGothic.eot?#iefix') format('embedded-opentype'),
        url('assets/fonts/CenturyGothic.woff2') format('woff2'),
        url('assets/fonts/CenturyGothic.woff') format('woff'),
        url('assets/fonts/CenturyGothic.ttf') format('truetype'),
        url('assets/fonts/CenturyGothic.svg#CenturyGothic') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


$font-family: Quicksand, sans-serif;

* {
    font-family: $font-family;
}

ngx-simplebar {
    // Normalerweise wächst die Scrollbar mit ihrem Inhalt und wird dabei nicht scrollbar.
    // Mit min-height 0 wächst sie, bis sie auf ein Hindernis stößt - meistens der Rand ihres
    // Elternelements - und wird ab dann scrollbar.
    min-height: 0;
}

// Google Charts Tooltip Flackern Bugfix
.google-visualization-tooltip {
    pointer-events: none;
}

/** Material Overrides **/
.mat-select-panel-h400.mat-select-panel-h400 {
    max-height: 400px;
}

.cdk-overlay-container {
    z-index: 1005; //one more than .e-quick-popup-wrapper.e-device fron syncfusion shedule
}
.mat-header-cell {
    font-weight: bold;
    font-size: medium;
}
.mat-tab-header .mat-tab-label-container {
    @include daveScrollBar();
    overflow-y: hidden;
}

.mat-date-range-input-start-wrapper.mat-date-range-input-start-wrapper,
.mat-date-range-input-end-wrapper.mat-date-range-input-end-wrapper {
    max-width: calc(50% - 8px); // werden zu groß standard ist max-width: calc(50% - 4px)
}
.mat-tooltip-show::first-letter,
.mat-menu-item::first-letter {
    text-transform: uppercase;
}
.mat-menu-item > mat-icon:first-child + span,
.mat-menu-item > fa-icon:first-child + span {
    display: inline-block;
    &::first-letter {
        text-transform: uppercase;
    }
}
.mat-menu-item > fa-icon:first-child {
    margin-right: 16px; //same as mat icon
}

.mat-menu-content {
    max-height: 300px;
    @include daveScrollBar();
}
.mat-menu-item fa-icon.mat-icon svg {
    vertical-align: -0.125em; // von fontawesome vorgegeben
}
.unseen-mat-badge.unseen-mat-badge .mat-badge-content {
    background: $unseen-color;
    color: $unseen-color-accent;
}

.mat-badge[matBadgePosition=center][matBadgePosition=center][matBadgePosition=center][matBadgePosition=center] .mat-badge-content.mat-badge-content.mat-badge-content.mat-badge-content {
    right: 50%;
    top: 50%;
    transform: translate(50%, -50%);
}
// z.B. für runde buttons
.mat-badge-more-overlay.mat-badge-more-overlay.mat-badge-more-overlay {
    $overlay-distance: -1px; // default -8
    &:not([matBadgePosition]) .mat-badge-content,
    &[matBadgePosition~=above] .mat-badge-content {
        top: $overlay-distance;
    }
    &:not([matBadgePosition]) .mat-badge-content,
    &[matBadgePosition~=after] .mat-badge-content {
        right: $overlay-distance;
    }
    &[matBadgePosition~=below] .mat-badge-content {
        bottom: $overlay-distance;
    }
    &[matBadgePosition~=before] .mat-badge-content {
        left: $overlay-distance;
    }
}


@media #{$desktop-query} {
    .mat-select-panel {
        max-width: 500px !important;
    }
}

$dialog-padding: .5rem;
mat-dialog-container.mat-dialog-container {
    padding: $dialog-padding;
}
mat-dialog-container .mat-dialog-actions {
    margin-bottom: -$dialog-padding;
    min-height: 3rem;
}
mat-dialog-container .mat-dialog-content {
    margin: 0 -$dialog-padding;
    padding: 0 $dialog-padding;
}
div.mat-tooltip {
    font-size: 100%;
    font-family: $font-family;
}
.mat-tooltip-white-space-pre {
    white-space: pre;
}

mat-action-list,
mat-nav-list,
mat-list {
    &.mat-list-base {
        padding-top: 0; // Standardmäßig 8px
    }
}

fa-icon[mat-list-avatar] {
    // Icon zentrieren
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

// mat-toolbar überschreibt die Standardtypographie für alle Überschriften mit Roboto
.mat-toolbar {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: $font-family;
    }
}

// Safari auf IOS kann die größe nicht selbst kalkulieren
.mat-tab-body-wrapper {
    height: 100%;
}

.mat-tab-body-content, textarea.mat-input-element {
    @include daveScrollBar();
    overflow: auto;
}

// FontAwesome Icons auf runden Buttons zentrieren
// Nur bei runden Buttons, da sie eine fixe Größe haben. Rechteckigen Buttons
// fehlt der Inhalt (wegen position: absolute), also würden sie 0px groß werden.
.mat-icon-button,
.mat-fab,
.mat-mini-fab,
.app-round-button,
.app-stroked-round-button {
    > span > fa-icon:only-child {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
.mat-form-field-type-app-time-span-picker-select {
    .mat-form-field-infix {
    width: 240px;
    }
}
// für Mat-datepicker
.mat-datepicker-green.mat-datepicker-green {
    background: green;
    border-radius: 100%;
}
.mat-datepicker-yellow.mat-datepicker-yellow {
    background: orange;
    border-radius: 100%;
}
.mat-datepicker-blue.mat-datepicker-blue {
    background: rgb(84, 84, 199);
    border-radius: 100%;
}

input[type=time]::-webkit-calendar-picker-indicator {
    cursor: pointer
}
input.hide-icon-from-browser::-webkit-calendar-picker-indicator {
     background: none;
 }
.custom-dialog-class-overflow-visible > .mat-dialog-container {
    overflow: visible;
}
.custom-dialog-class-without-padding {
    .mat-dialog-container {
        padding: 0;
    }
}
/* Entfernt in D277-4473, da diese Klassen den Style der Buttons überschreibt
.save-active {
    background-color: #44aa22 !important;
    border-color: #44aa22 !important;
    color: white;
}
.save-active:hover{
    background-color: white !important;
    color: #44aa22 !important;
}
.delete-active {
    background-color: red !important;
    border-color: red !important;
    color: white;
}
.delete-active:hover{
    background-color: white !important;
    color: red !important;
}
*/
.custom-dialog-class-without-overflow{
    .mat-dialog-container {
        overflow-y: hidden;
    }
}
.mat-dialog-with-close-button .mat-dialog-container {
    position: relative;
    padding-top: 2.5rem;
    .close-button {
        font-size: 1.5rem;
        position: absolute;
        top: 0; // $dialog-padding;
        right: 0; // $dialog-padding;
    }
}
.mat-dialog-container, .mat-dialog-content {
    @include daveScrollBar();
}
@mixin _dialog-fullscreen() {
    position: absolute !important;
    height: calc(100% - 3.5rem) !important;
    max-height: calc(100% - 3.5rem) !important;
    width: 100% !important;
    max-width: 100% !important;
    top: 3.5rem;
    .mat-dialog-container{
        max-height: 100%;
    }
    @media #{$touch-query} {
        .mat-dialog-actions {
            padding-bottom: 2rem;
            margin-top: auto;
        }
    }
    .mat-dialog-content {
        max-height: unset;
    }

    &.no-header {
        height: 100% !important;
        max-height: 100% !important;
        top: 0;
    }
}
.custom-dialog-class-fullscreen {
    @include _dialog-fullscreen();
}
.custom-dialog-class-mobile-fullscreen {
    @media #{$mobile-query} {
        @include _dialog-fullscreen();
    }
}
.custom-dialog-class-small-fullscreen, .custom-dialog-class-tablet-fullscreen {
    @media #{$small-query} {
        @include _dialog-fullscreen();
    }
}

 .mat-dialog-container {
    .close-mat-dialog-button {
        position: absolute;
        right: 0;
        top: 0;
        > span > fa-icon:only-child {
            display: flex;
        }
    }
}
.custom-dialog-class-for-overlay-on-right-side {
    .mat-dialog-container {
        padding-top: 0;
        z-index: 1000;
        position: fixed;
        width: 50vh;
        min-width: 18rem;
        top: 3.5rem;
        height: calc(100vh - 3.5rem);
        box-sizing: border-box;
        right: 0;
        @media #{$mobile-query} {
            width: 100vw;
            top: 6.5rem;
            height: calc(100% - 6.5rem);
        };
    }
}
.custom-dialog-class-above-loading-page {
    z-index: 10010;

}
.custom-dialog-class-without-padding-margin-top-1-75 {
    .mat-dialog-container {
        margin-top: 1.75rem;
        padding: 0;
    }
}

.filter-with-increased-height {
    max-height: 300px !important;
}
.mat-select-panel {
    @include daveScrollBar();
}
:root {
    .ck-body-wrapper {
        --ck-z-modal: 1005;
    }
}
ckeditor {
    .ck.ck-editor__main > .ck-editor__editable {
        //background: unset;
    }
}

.small-mat-tab-label.small-mat-tab-label {
    min-width: 90px;
}

// ejs (syncfusion) overrides
.e-quick-popup-wrapper .e-event-popup .e-popup-content .e-text-ellipsis,
.e-quick-popup-wrapper .e-event-popup .e-popup-content .e-date-time-icon {
    font-size: 1.2rem;
}
.e-quick-popup-wrapper .e-event-popup .e-popup-header .e-subject-wrap .e-subject {
    font-weight: bold;
    font-size: 1.4rem;
}
.app-clickable-background.app-clickable-background.app-clickable-background.app-clickable-background {
    background-color: var(--app-clickable-background-color);
    &.is-active,
    &:hover,
    &:active,
    &:focus {
        filter: brightness(85%);
    }
}
.dark-theme .app-clickable-background.app-clickable-background.app-clickable-background.app-clickable-background {
    &:hover,
    &:active,
    &:focus {
        filter: brightness(115%);
    }
}
.whitespace-pre-wrap {
    white-space: pre-wrap;
}
